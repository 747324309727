import React, { Component, Fragment } from 'react'
import { withIntl, FormattedMessage } from 'gatsby-plugin-intl'
import SEO from '../components/seo'
import Inner from '../components/layout/inner'

import { Icons, IconAdultOnly } from '../components/layout/icons'

import { Header } from '../components/layout/top'

import { About } from '../components/layout/about'
import ActionsPanel from '../components/layout/actions'
import Character from '../components/layout/character'

// Style
import '../sass/styles.scss'

class NotFoundPage extends Component {
  handleClick() {
    document.location.href = '/'
  }

  render() {
    const { intl } = this.props

    return (
      <Fragment>
        <SEO title={`${intl.formatMessage({ id: 'title' })}`} />
        <Icons />
        <main className="content" role="main">
          <About />

          <div className="inner-ctn">
            <div className="top-ctn">
              <Header />
            </div>

            <div className="ui-ctn">
              <ActionsPanel />
              <Character />
            </div>
          </div>

          <div className="steps-ctn">
            <div className="step step-1 intro current">
              <Inner noborder className="bottom">
                <h1>
                  404 : Page <FormattedMessage id={`notfound`} />
                </h1>
                <div className="btn-ctn">
                  <button
                    type="button"
                    className="btn js-next-step"
                    data-layer='{"eventAction":"Click","eventLabel":"404-reload","eventCategory":"UI Component"}'
                    onClick={this.handleClick}
                  >
                    <FormattedMessage id={`back`} />
                  </button>
                </div>
              </Inner>
            </div>
          </div>
          <IconAdultOnly />
        </main>
      </Fragment>
    )
  }
}

export default withIntl(NotFoundPage)
