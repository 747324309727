import React, { Fragment } from 'react'
import { injectIntl } from 'gatsby-plugin-intl'

export const About = () => (
  <Fragment>
    <span className="js-article-toggle article-toggle">About</span>
    <div className="article-ctn js-article-toggle">
      <article className="step current">
        <div className="inner">
          <div className="scrolling-content">
            <h1>
              SexEmulator – <strong>Building the Woman of your Dreams</strong>{' '}
              One Skill at a Time
            </h1>
            <p>
              <strong>Building the babe of your dreams</strong> sounds like
              that…simply a dream. An impossible fantasy. Something that will
              never happen in a million years. Well, in case you did not know,
              virtually it is very possible. SexEmulator does that and a hell of
              a lot more. It’s easily one of the most entertaining adult sex
              games ever released for the web. Warning: once you play, you will
              not be able to stop.{' '}
              <strong>To say SexEmulator is an addictive adult sex game</strong>{' '}
              would be the hugest understatement in history. Just imagine, a
              porn game that lets you build the perfect woman of your dreams.
              But it doesn’t end there, not by a long shot. SexEmulator lets you
              train your wonderful woman in all kinds of nasty but useful sexual
              skills: blowjobs, spanking, anal sex, straight fucking, bdsm and
              more! Once you’re on SexEmulator, the fun never ends!
            </p>
            <p>
              When we say you can build the woman of your dreams, we literally
              mean you can actually{' '}
              <strong>
                <em>build</em> the woman of your dreams.
              </strong>{' '}
              You – the player – get to create and customize every single aspect
              of your perfect babe. SexEmulator is the{' '}
              <strong>ultimate fantasy porn video game</strong>, where
              everything is customizable and the only limit is your own
              imagination. The game is about creating your ultimate dream girl
              and commanding her as she lives out all your wildest sexual
              fantasies. Watch her level up through tons of different scenarios
              and locations. SexEmulator will change the way you look{' '}
              <strong>at adult video games</strong> forever! Trust us, you
              haven’t played a true <strong>interactive porn game</strong> until
              you’ve visited SexEmulator and checked out everything we have to
              offer. The porn gaming industry is about to undergo a complete
              overhaul and revolution. It’s never been done before – welcome the
              ultimate virtual porn fantasy!
            </p>
            <p />
            <h2>
              Experience the <strong>Sexual Adventure of SexEmulator</strong>
            </h2>
            <p>
              Take our hand as we guide you through a{' '}
              <strong>porn fantasy</strong> unlike anything you’ve ever
              experience before in your life. Come on a sexual adventure that
              will change the way you think of adult entertainment for the rest
              of your life. These days, porn is about a lot more than simply{' '}
              <strong>watching hardcore porn videos</strong> on the web. People
              want something REAL and LIVE, not recorded and fake. More than
              anything, porn surfers are seeking <em>interaction</em>. Something
              they can customize and change so it fits their fantasy perfectly.
              Inside SexEmulator, you can decide absolutely everything about
              your character. That means you get to choose stuff like her sexual
              skills, talents and preferences. Also her ethnicity, age,
              language, name – but more importantly, where and who she fucks!
              She gets to do all your favorite positions as she continues to
              level up, adding more and more skills and talents to her sexual
              arsenal.
            </p>
            <p>
              Get ready to dip into a virtual world where everything and
              anything you desire is not only possible, but literally within
              your grasp. By simply pressing a few keys your character will
              continue her quest to become the ultimate sex goddess. Players can
              do anything they want – the sky is the limit! On SexEmulator, your
              perfect babe can become the perfect cock sucking slut who uses her
              mouth and tongue with great skill, giving everyone amazing
              blowjobs until she’s bumped up to the next level and you can
              acquire additional sex skills. Or perhaps you want to watch your{' '}
              <strong>sexy female character</strong> participate in a few
              ruthless gangbangs, getting fucked by a dozen black guys at the
              same time. Are you starting to get the picture?{' '}
              <strong>Teach your porn character</strong> everything she needs to
              know about sex to continue to level up: cock sucking, ball
              sucking, ass fucking, gangbang sex, bondage – it’s a never-ending
              porn adventure!
            </p>
            <p />
            <h2>
              <strong>Free Porn Videos</strong> + Access to More Adult Games!
            </h2>
            <p>
              When you want to take a break from playing with your new porn
              character, SexEmulator offers more fun stuff to keep you busy.
              Signing up to SexEmulator also gives you access tons of{' '}
              <strong>premium sex videos</strong> plus other adult sex games
              that are sure to keep you entertained for months to come. What
              other website lets you play not one but several{' '}
              <strong>amazing adult video games</strong> and watch the hottest
              porn videos on the web? Clearly, SexEmulator was created to be a
              high quality form of sex entertainment. The porn video game itself
              – where you get to create your own character and fuck her all the
              way to stardom – is reason enough to{' '}
              <strong>sign up to SexEmulator.com</strong> and experience it for
              yourself. Don’t wait a second longer. Come and see why everyone is
              talking about SexEmulator, the groundbreaking{' '}
              <strong>new porn game</strong>.
            </p>
            <p>
              SexEmulator is the{' '}
              <strong>definitive interactive porn game experience</strong>.
              Nothing on or off the web can even come close to the adventures
              you’ll have once you start playing this stunning sex video game.
              Get comfortable, buckle your seatbelt and get ready for one hell
              of a bumpy ride! Pretty soon you can be creating the{' '}
              <strong>perfect porn character</strong>, ready to suck and fuck
              her way level through level while she lives out all of your
              deepest sex dreams on camera! Everything that you want to happen,
              can happen. Players at SexEmulator have complete control over
              their characters and fuck their way through dozens of different
              scenarios and locations. Imagine the thousands of different sexual
              adventures you could be having right now.
            </p>
            <p>
              <strong>
                Join SexEmulator and start building the perfect woman today!
              </strong>
            </p>
          </div>
        </div>
      </article>
    </div>
  </Fragment>
)

export default injectIntl(About)
